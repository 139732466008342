import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface IndexSetIdAndType {
    readonly index_set_id: string;
    readonly type: string;
}
interface EntityAttribute {
    readonly filterable: boolean;
    readonly related_property: string;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly sortable: boolean;
    readonly id: string;
    readonly type: 'STRING' | 'DATE' | 'DOUBLE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
type IndexSetIdAndTypeArray = IndexSetIdAndType[];
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
type IndexSetFieldTypeArray = IndexSetFieldType[];
interface FieldTypeSummaryRequest {
    readonly field: string;
    readonly streams: string[];
}
interface IndexSetFieldTypeSummary {
    readonly stream_titles: string[];
    readonly index_set_title: string;
    readonly types: string[];
    readonly index_set_id: string;
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
interface PageListResponse_IndexSetFieldTypeSummary {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: IndexSetFieldTypeSummary[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: 'asc' | 'desc';
}
interface IndexSetFieldType {
    readonly is_reserved: boolean;
    readonly origin: 'INDEX' | 'PROFILE' | 'OVERRIDDEN_INDEX' | 'OVERRIDDEN_PROFILE';
    readonly type: string;
    readonly field_name: string;
}
interface PageListResponse_IndexSetFieldType {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: IndexSetFieldType[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: 'asc' | 'desc';
}
interface EntityDefaults {
    readonly sort: Sorting;
}
type stringArray = string[];
/**
 * Get field type summaries for given index sets and field
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function fieldTypeSummaries(JSONbody: FieldTypeSummaryRequest, sort: 'index_set_id' | 'index_set_title' = 'index_set_id', page: number = 1, per_page: number = 50, order: 'asc' | 'desc' = 'asc'): Promise<PageListResponse_IndexSetFieldTypeSummary> {
    return __request__('POST', '/system/indices/index_sets/types', JSONbody, { 'page': page, 'per_page': per_page, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Get field type summaries for given index sets and field
 */
export function indexSetsWithFieldTypeChangeSupport(Streamids: stringArray): Promise<IndexSetIdAndTypeArray> {
    return __request__('POST', '/system/indices/index_sets/types/index_sets_with_field_type_change_support', Streamids, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Gets list of field_name-field_type pairs for given index set, paginated
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function indexSetFieldTypesList(sort: 'field_name' | 'type' | 'origin' | 'is_reserved' = 'field_name', index_set_id?: string, query?: string, filters?: stringArray, page: number = 1, per_page: number = 50, order: 'asc' | 'desc' = 'asc'): Promise<PageListResponse_IndexSetFieldType> {
    return __request__('GET', `/system/indices/index_sets/types/${index_set_id}`, null, { 'query': query, 'filters': filters, 'page': page, 'per_page': per_page, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Gets list of all field_name-field_type pairs for given index set
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function indexSetFieldTypesListByindex_set_idAndfieldNameQueryAndfiltersAndsortAndorder(sort: 'field_name' | 'type' | 'origin' | 'is_reserved' = 'field_name', index_set_id?: string, fieldNameQuery?: string, filters?: stringArray, order: 'asc' | 'desc' = 'asc'): Promise<IndexSetFieldTypeArray> {
    return __request__('GET', `/system/indices/index_sets/types/${index_set_id}/all`, null, { 'fieldNameQuery': fieldNameQuery, 'filters': filters, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
