import __request__ from 'routing/request';
interface PaginatedResponse_StreamDestinationFilterRuleDTO {
    readonly [_key: string]: Object;
}
interface Object {
}
interface RuleBuilder {
    readonly conditions: RuleBuilderStep[];
    readonly actions: RuleBuilderStep[];
    readonly operator: 'AND' | 'OR';
    readonly errors: string[];
}
interface RuleBuilderStep {
    readonly step_title: string;
    readonly negate: boolean;
    readonly function: string;
    readonly id: string;
    readonly params: {
        readonly [_key: string]: Object;
    };
    readonly outputvariable: string;
    readonly conditions: RuleBuilderStep[];
    readonly operator: 'AND' | 'OR';
    readonly errors: string[];
}
interface StreamDestinationFilterRuleDTO {
    readonly destination_type: string;
    readonly stream_id: string;
    readonly description: string;
    readonly rule: RuleBuilder;
    readonly id: string;
    readonly title: string;
    readonly status: 'enabled' | 'disabled';
}
interface PaginationParameters {
    readonly per_page: number;
    readonly query: string;
    readonly page: number;
    readonly sort_by: string;
    readonly order: string;
}
/**
 * Create new filter rule
 */
export function createFilter(streamId: string, JSONBody: StreamDestinationFilterRuleDTO): Promise<unknown> {
    return __request__('POST', `/streams/${streamId}/destinations/filters`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get available filter rules for stream
 */
export function getPaginatedFiltersForStream(streamId: string, paginationparameters?: PaginationParameters): Promise<PaginatedResponse_StreamDestinationFilterRuleDTO> {
    return __request__('GET', `/streams/${streamId}/destinations/filters`, paginationparameters, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update filter rule
 */
export function updateFilter(streamId: string, filterId: string, JSONBody: StreamDestinationFilterRuleDTO): Promise<unknown> {
    return __request__('PUT', `/streams/${streamId}/destinations/filters/${filterId}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete filter rule
 */
export function deleteFilter(streamId: string, filterId: string): Promise<unknown> {
    return __request__('DELETE', `/streams/${streamId}/destinations/filters/${filterId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get filter rule for given ID
 */
export function getFilter(streamId: string, filterId: string): Promise<unknown> {
    return __request__('GET', `/streams/${streamId}/destinations/filters/${filterId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get available filter rules for stream and target
 */
export function getPaginatedFiltersForStreamAndTarget(streamId: string, targetId: string, paginationparameters?: PaginationParameters): Promise<PaginatedResponse_StreamDestinationFilterRuleDTO> {
    return __request__('GET', `/streams/${streamId}/destinations/target/${targetId}/filters`, paginationparameters, {}, {
        'Accept': ['application/json']
    });
}
